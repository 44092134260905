'use client'

import { cn } from '@chaseweb/utils/cn'
import Script from 'next/script'
import { useEffect, useRef } from 'react'

import { Link } from '@/components/link'

declare global {
  interface Window {
    Trustpilot?: {
      loadFromElement: (ref: HTMLElement, bool: boolean) => unknown
    }
  }
}

export interface TrustpilotWidgetProps {
  className?: string
}

export const TrustpilotWidget = ({ className }: TrustpilotWidgetProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoad = () => {
    if (ref.current && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }

  return (
    <>
      <Script
        strategy="afterInteractive"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        onLoad={handleLoad}
      />
      <Link
        aria-label="See our reviews on Trustpilot"
        trackingActionLabel="TrustpilotLink"
        sectionLabel="BoastBar "
        href="https://uk.trustpilot.com/review/chase.co.uk?utm_medium=trustbox&utm_source=MicroReviewCounter"
      >
        <div
          {...{ inert: '' }}
          ref={ref}
          className={cn('trustpilot-widget tw-pointer-events-none', className)}
          data-locale="en-GB"
          data-template-id="5419b6a8b0d04a076446a9ad"
          data-businessunit-id="614bbe2966c7ad001d489e2b"
          data-style-height="24px"
          data-style-width="100%"
          data-theme="light"
          data-style-alignment="center"
          aria-hidden={true}
          tabIndex={-1}
        />
      </Link>
    </>
  )
}

TrustpilotWidget.displayName = 'TrustpilotWidget'
