'use client'

import { Button, Hero as HeroUI } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import { useSplitStore } from '@chaseweb/utils/split'
import { useEffect, useRef } from 'react'

import { DownloadLink } from '@/components'
import type { FeatureTypeIdEnum } from '@/constants'
import { useCTAStore } from '@/lib/stores'

import type { ComponentProps } from '../../types'

export const HeroCta = ({
  featureData,
}: ComponentProps<FeatureTypeIdEnum.HERO_FEATURE>) => {
  const client = useSplitStore((state) => state.clients.user)
  const treatmentCtaText = client?.getTreatmentWithConfig(
    'homepage_hero_cta_text',
  )
  const treatmentCtaSize = client?.getTreatment('homepage_hero_cta_size')
  const treatmentCtaColour = client?.getTreatment('hero_cta_colour')

  const ctaRef = useRef<HTMLDivElement>(null)
  const { setHeroCtaRef, setAlwaysVisible } = useCTAStore()

  useEffect(() => {
    if (client) {
      if (ctaRef.current) {
        setHeroCtaRef(ctaRef)
        setAlwaysVisible(false)
      } else {
        setAlwaysVisible(true)
      }
    }
    return () => setHeroCtaRef(null)
  }, [setHeroCtaRef, client, setAlwaysVisible])

  const ctaLabel = featureData.isHomepage
    ? (
        JSON.parse(
          treatmentCtaText?.config || '{"inlineText":"Get the app"}',
        ) as {
          inlineText: string
        }
      )['inlineText']
    : 'Get the app'

  const isCtaBigSize = treatmentCtaSize === 'on' && featureData.isHomepage
  const isCtaWhite = treatmentCtaColour === 'on' && featureData.isHomepage

  return featureData.cta ? (
    <div ref={ctaRef} className="tw-min-h-[75px]">
      {client && (
        <HeroUI.Cta
          variant={isCtaWhite ? 'secondary' : featureData.variant}
          asChild
          noExternalIcon
          className={cn({
            'tw-px-8 tw-py-4': isCtaBigSize,
          })}
        >
          <DownloadLink sectionLabel="hero">{ctaLabel}</DownloadLink>
        </HeroUI.Cta>
      )}
      <noscript>
        <Button
          asChild
          variant="secondary"
          noExternalIcon
          size="sm"
          className={cn('tw-mt-6 tw-w-fit tw-px-6 tw-py-3 tw-font-semibold')}
        >
          <DownloadLink sectionLabel="hero">Get the app</DownloadLink>
        </Button>
      </noscript>
    </div>
  ) : null
}
