import { forwardRef } from 'react'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from 'react'
import { Heading, CardProfile } from '@chaseweb/ui-library/src/atoms'
import { cn } from '@chaseweb/utils/cn'

const Root = forwardRef<
  ElementRef<typeof CardProfile>,
  ComponentPropsWithoutRef<typeof CardProfile>
>(({ className, children, shape, ...props }, ref) => (
  <CardProfile
    ref={ref}
    className={cn(
      'tw-group/editorial tw-flex tw-flex-col tw-bg-white tw-shadow-[0_0_0.6875rem_0_#0000001f]',
      className,
    )}
    shape={shape ?? 'rounded-lg'}
    data-ui-component="EditorialCard"
    {...props}
  >
    {children}
  </CardProfile>
))
Root.displayName = 'EditorialCard.Root'

const ImageWrapper = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        className={cn(
          'tw-relative tw-flex tw-aspect-square tw-overflow-hidden [&>*]:tw-transition-transform [&>*]:tw-duration-250 [&>*]:tw-ease-in-out [&_*]:tw-min-h-full [&_*]:tw-w-full [&_*]:tw-object-cover [&_*]:tw-object-center',
          '[&>*]:group-hover/editorial:tw-scale-110',
          'motion-reduce:[&>*]:group-hover/editorial:tw-scale-100',
          className,
        )}
        {...props}
        ref={ref}
      />
    )
  },
)
ImageWrapper.displayName = 'EditorialCard.ImageWrapper'

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'tw-mb-6 tw-w-full tw-bg-white tw-px-4 tw-pt-6 tw-transition-transform tw-duration-250 tw-ease-in-out group-hover/editorial:-tw-translate-y-2',
        className,
      )}
      {...props}
    />
  ),
)
Content.displayName = 'EditorialCard.Content'

interface LabelAndDisclaimerProps extends HTMLAttributes<HTMLDivElement> {
  classNameItem?: string
  'data-testid'?: string
}

const Label = forwardRef<HTMLDivElement, LabelAndDisclaimerProps>(
  (
    { className, 'data-testid': dataTestId = 'editorial-label', ...props },
    ref,
  ) => (
    <div
      data-testid={dataTestId}
      ref={ref}
      className={cn('tw-mb-2 tw-font-semibold', className)}
      {...props}
    />
  ),
)
Label.displayName = 'EditorialCard.Label'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  PartialBy<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, as = 'h4', type = 'h4', ...props }, ref) => (
  <Heading
    ref={ref}
    as={as}
    type={type}
    className={cn('tw-font-semibold', className)}
    {...props}
  />
))
Title.displayName = 'EditorialCard.Title'

const Disclaimer = forwardRef<HTMLDivElement, LabelAndDisclaimerProps>(
  (
    { className, 'data-testid': dataTestId = 'editorial-disclaimer', ...props },
    ref,
  ) => (
    <div
      data-testid={dataTestId}
      ref={ref}
      className={cn('tw-mt-2 tw-text-grey40', className)}
      {...props}
    />
  ),
)
Disclaimer.displayName = 'EditorialCard.Disclaimer'

export { Root, Content, ImageWrapper, Label, Title, Disclaimer }
