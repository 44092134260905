'use client'

import { ScreenReader, SVG } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import throttle from 'lodash/throttle'
import { useRouter, useSearchParams } from 'next/navigation'
import type { ChangeEvent } from 'react'
import {
  type FocusEventHandler,
  type HTMLAttributes,
  type KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Link } from '@/components'
import type { SupportArticle } from '@/data/aem'
import { useHasRehydrated } from '@/lib/hooks/useHasRehydrated'
import type { RelativePathType } from '@/types'

import {
  configureSearchEngine,
  formRegexForMultiWordSearch,
  getSearchResults,
  highlightWords,
} from './utils'

export const SEARCH_BUTTON_DISABLED_TEXT =
  'Search button disabled. Please enter a search term.'
export const SEARCH_BUTTON_ENABLED_TEXT = 'Search button enabled.'
const SUGGESTIONS = 'Suggestions'
const SUGGESTIONS_INFO =
  'Suggestions found, use up and down arrows to review and enter to select'
const MAX_SUGGESTIONS = 5

export interface SearchBarProps extends HTMLAttributes<HTMLInputElement> {
  placeholder: string
  /**
   * type of data being passed to search bar
   */
  articleList: SupportArticle[]
  /**
   * Set prefix for href of suggestion items
   */
  hrefPrefix?: RelativePathType
  /**
   * Style set for search bar container
   */
  classNameContainer?: string
}

export const SearchBar = ({
  id,
  articleList,
  hrefPrefix = '/',
  placeholder,
  classNameContainer,
  ...props
}: // eslint-disable-next-line sonarjs/cognitive-complexity
SearchBarProps) => {
  const hasRehydrated = useHasRehydrated()
  const searchParams = useSearchParams()
  const inputRef = useRef<HTMLInputElement>(null)
  const resetButtonRef = useRef<HTMLButtonElement>(null)
  const suggestionItemRef = useRef<HTMLLIElement[]>([])
  const [activeSuggestion, setActiveSuggestion] = useState(-1)
  const [isInputActive, setIsInputActive] = useState(false)
  const [showSuggestion, setShowSuggestion] = useState(false)
  const [inputValue, setInputValue] = useState(searchParams.get('query') ?? '')
  const [searchResultList, setSearchResultList] = useState<SupportArticle[]>([])
  const router = useRouter()

  const search = useMemo(() => {
    const transformedNodes = articleList.map(
      (item: SupportArticle, index: number) => ({
        index,
        ...item,
        content: {
          __html__: item.content.__html__.replace(
            /^([\s\S]+?)<h.>Related FAQs[\s\S]*?<\/h.>[\s\S]+$/,
            '$1',
          ),
        },
      }),
    )
    return configureSearchEngine(transformedNodes, 'index', [
      'title',
      'content',
    ])
  }, [articleList])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleGetResults = useCallback(
    throttle(
      (value: string) => {
        const results = getSearchResults(value, search)
        setSearchResultList(results)
      },
      1000,
      {
        leading: false,
        trailing: true,
      },
    ),
    [search],
  )

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      if (value.trim() === '') {
        setInputValue('')
        setSearchResultList([])
        return
      }
      throttleGetResults(value)
      setInputValue(value)
    },
    [throttleGetResults],
  )
  useEffect(() => {
    // if inputValue is set via search params...
    if (inputValue) {
      const results = getSearchResults(inputValue, search)
      setSearchResultList(results)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleReset = () => {
    setInputValue('')
    setSearchResultList([])
  }

  const filteredSuggestions = searchResultList
    .slice(0, MAX_SUGGESTIONS)
    .map((item, suggestionId) => ({
      suggestionId,
      ...item,
    }))
  const isSearchQueryValid = inputValue && inputValue.toString().trim() !== ''

  /**
   * Navigate to first item in list on keydown event of input
   * @param e
   */
  const onInputKeyDownPress = (e: KeyboardEvent<HTMLInputElement>) => {
    setShowSuggestion(true)
    setIsInputActive(false)
    // Focus on first list item
    if (e.key === 'ArrowDown' || e.key === 'Down') {
      e.preventDefault()
      ;(suggestionItemRef.current[0].children[0] as HTMLAnchorElement)?.focus()
      setActiveSuggestion(0)
    }
    // As focus moves out of input field, we won't show suggestion list
    if (e.key === 'Tab') {
      setShowSuggestion(false)
    }
    // On enter should behave like click of search button
    if (e.key === 'Enter') {
      setShowSuggestion(false)
      if (isSearchQueryValid) {
        router.push(`${hrefPrefix}search/?query=${inputValue}`)
      }
    }
  }

  /**
   * On focussing back to input, suggestions should appear back if it was collapsed earlier
   */
  const onFocusOfInput: FocusEventHandler<HTMLInputElement> = (e) => {
    setShowSuggestion(filteredSuggestions.length > 0)
    setIsInputActive(e.target.getAttribute('data-input') === 'keyboard')
  }

  /**
   * Navigate through suggestion list
   * @param e
   */
  const navigateSuggestionList = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault()
        navigateUp()
        break
      case 'ArrowDown':
        e.preventDefault()
        navigateDown()
        break
      case 'Tab':
        e.preventDefault()
        setFocusToClearButton()
        break
      default:
        break
    }
  }

  /**
   * Navigate down the suggestion list
   */
  const navigateDown = () => {
    if (activeSuggestion === filteredSuggestions.length - 1) {
      suggestionItemRef.current[0].focus()
      ;(suggestionItemRef.current[0].children[0] as HTMLAnchorElement).focus()
      setActiveSuggestion(0)
      return
    }
    ;(
      suggestionItemRef.current[activeSuggestion + 1]
        .children[0] as HTMLAnchorElement
    ).focus()
    setActiveSuggestion(activeSuggestion + 1)
  }

  /**
   * Navigate up the suggestion list
   */
  const navigateUp = () => {
    if (activeSuggestion === 0) {
      inputRef.current?.focus() // condition for focus on input is covered in tests
      return
    }
    ;(
      suggestionItemRef.current[activeSuggestion - 1]
        .children[0] as HTMLAnchorElement
    ).focus()
    setActiveSuggestion(activeSuggestion - 1)
  }

  /**
   * Once we are at last suggestion item then on press of tab it should focus on reset button
   */
  const setFocusToClearButton = () => {
    resetButtonRef.current?.focus() // condition for focus on reset button is covered in tests
    suggestionItemRef.current[activeSuggestion].blur()
    setActiveSuggestion(-1)
    setShowSuggestion(false)
  }

  /**
   * Highlight search query words in suggestion box
   * @param title
   */
  const highlightSearchWords = (title: string) => {
    const input = `${inputValue}`.toLowerCase().replace(/[^\w\s]/gi, '')
    const keyWords = input.split(' ')
    // highlight single word search with prefix strategy
    if (title.toLowerCase().includes(input)) {
      return highlightWords(title, new RegExp(`(\\b)(${input})`, 'gi'))
    }
    // highlight multiple words in same sentence
    if (keyWords.length > 1) {
      const groups = formRegexForMultiWordSearch(keyWords)
      return highlightWords(title, new RegExp(groups, 'gi'))
    }
    // if search results are available but input query doesn't match the
    // keywords then just show the title without any highlighter
    if (!title.toLowerCase().includes(input)) {
      return (
        <span className="tw-text-sm tw-font-normal tw-not-italic tw-shadow-none">
          {title}
        </span>
      )
    }
  }

  const isClearButtonVisible = inputValue && inputValue.toString().trim() !== ''

  return (
    <div className={cn('tw-relative tw-w-full', classNameContainer)}>
      <div
        className={cn(
          'tw-relative tw-mb-1 tw-box-border tw-flex tw-h-14 tw-rounded-lg tw-bg-grey90 tw-ring-1 tw-ring-grey60',
          'focus-within:tw-ring-2 focus-within:tw-ring-blue30 hover:tw-ring-grey30 hover:focus-within:tw-ring-blue30',
        )}
      >
        <input
          {...props}
          disabled={!hasRehydrated}
          type="text"
          id={`${id}_search_input`}
          value={inputValue}
          placeholder={placeholder}
          className={cn(
            'tw-peer tw-z-10 tw-w-full tw-flex-grow tw-items-center tw-rounded-l-lg tw-rounded-r-none tw-border-none tw-bg-transparent tw-p-4 tw-pr-10 tw-outline-none',
            'tw-mr-0.5 placeholder:tw-text-text placeholder-shown:tw-text-ellipsis',
            {
              'focus-visible:[&[data-input=keyboard]]:tw-ring-2 focus-visible:[&[data-input=keyboard]]:tw-ring-blue60':
                isInputActive,
            },
          )}
          ref={inputRef}
          autoComplete="off"
          onKeyDown={onInputKeyDownPress}
          onFocus={onFocusOfInput}
          onChange={handleInputChange}
          aria-autocomplete="both"
          role="combobox"
          aria-owns={`${id}_search_input`}
          aria-controls={`${id}_suggestions`}
          aria-expanded={showSuggestion}
        />
        {filteredSuggestions.length > 0 && (
          // only readable by screen reader to inform users about number of suggestions available
          <ScreenReader.Message aria-live="assertive" role="status">
            {`${filteredSuggestions.length} ${SUGGESTIONS_INFO}`}
          </ScreenReader.Message>
        )}
        <button
          disabled={!hasRehydrated}
          id={`${id}_reset_button`}
          className={cn(
            'tw-absolute tw-right-18 tw-top-4 tw-z-20 tw-flex tw-h-6 tw-w-6 tw-items-center tw-border-none tw-bg-transparent tw-opacity-0 tw-outline-none tw-transition-opacity',
            'focus-visible:tw-opacity-100 focus-visible:tw-ring-2 focus-visible:tw-ring-blue60',
            {
              'tw-opacity-100': isClearButtonVisible,
            },
          )}
          tabIndex={isClearButtonVisible ? 0 : -1}
          aria-hidden={!isClearButtonVisible}
          type="reset"
          ref={resetButtonRef}
          aria-label="Clear Search"
          onClick={handleReset}
        >
          <SVG name="Clear" />
        </button>
        <Link
          href={
            isSearchQueryValid
              ? {
                  pathname: `${hrefPrefix}search/`,
                  query: { query: inputValue },
                }
              : {}
          }
          className={cn(
            'tw-z-[5] tw-flex tw-w-14 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-r-lg tw-border-0 tw-bg-transparent tw-outline-none tw-transition-colors',
            'focus-visible:tw-ring-2 focus-visible:tw-ring-blue60',
            {
              'tw-bg-blue30 tw-text-white tw-ring-1 tw-ring-blue30 focus-visible:tw-bg-blue10':
                !!inputRef.current?.value,
            },
          )}
          trackingActionLabel="Support Search"
          sectionLabel="Support Search Bar"
          aria-label={
            isSearchQueryValid
              ? SEARCH_BUTTON_ENABLED_TEXT
              : SEARCH_BUTTON_DISABLED_TEXT
          }
        >
          <SVG name="Search" />
        </Link>
      </div>
      {inputValue !== '' && // Suggestion box should be visible if search query has any results
        filteredSuggestions.length > 0 && (
          <div
            className={`tw-absolute tw-z-50 -tw-mt-0.5 tw-box-border tw-w-full tw-rounded-lg tw-border tw-border-grey80 tw-bg-white tw-p-4 tw-shadow ${
              !showSuggestion ? 'tw-hidden' : ''
            }`}
            id={`${id}_suggestions`}
            role="listbox"
            aria-labelledby={`${id}_suggestions_label`}
          >
            <p
              role="heading"
              className="tw-m-4 tw-block tw-text-sm tw-font-normal tw-uppercase tw-not-italic tw-shadow-none"
              aria-level={2}
              id={`${id}_suggestions_label`}
            >
              {SUGGESTIONS}
            </p>
            <ul>
              {filteredSuggestions.map(({ slug, title, suggestionId }) => {
                return (
                  <li
                    className="tw-m-4 tw-block tw-outline-none tw-ring-offset-2 focus-within:tw-ring-1 focus-within:tw-ring-blue60"
                    role="option"
                    aria-roledescription="link"
                    aria-selected={suggestionId === activeSuggestion}
                    key={`${slug}${suggestionId}`}
                    ref={(el) =>
                      (suggestionItemRef.current[suggestionId] =
                        el as HTMLLIElement)
                    }
                    onKeyDown={navigateSuggestionList}
                  >
                    <Link
                      href={`${hrefPrefix}${slug}`}
                      trackingActionLabel={title}
                      sectionLabel="Support Suggestions"
                      className="tw-text-secondaryText tw-no-underline tw-shadow-none focus-visible:tw-border-none focus-visible:tw-outline-none"
                    >
                      {highlightSearchWords(title)}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
    </div>
  )
}
