'use client'
import { forwardRef } from 'react'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from 'react'
import { Slot } from '@radix-ui/react-slot'
import * as SeparatorRadix from '@radix-ui/react-separator'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@chaseweb/utils/cn'
import { Button } from '../../atoms/button'
import { Heading } from '../../atoms/heading'
import { Prose } from '../../atoms/prose'

const rootVariants = cva('', {
  variants: {
    align: {
      left: 'tw-text-left',
      center: 'tw-text-center',
    },
  },
  defaultVariants: {
    align: 'left',
  },
})

interface RootProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof rootVariants> {
  asChild?: boolean
  'data-testid'?: string
}

const Root = forwardRef<HTMLDivElement, RootProps>(
  (
    {
      className,
      asChild,
      'data-testid': dataTestId = 'feature-text-root',
      align = 'left',
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <Comp
        data-testid={dataTestId}
        ref={ref}
        className={cn(rootVariants({ align, className }))}
        {...props}
      />
    )
  },
)
Root.displayName = 'FeatureText.Root'

interface LabelProps extends HTMLAttributes<HTMLDivElement> {
  highlight?: boolean
}

const Label = forwardRef<HTMLDivElement, LabelProps>(
  ({ className, highlight = false, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'tw-mb-4 tw-inline-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded tw-bg-blue90 tw-px-3 tw-py-[6px] tw-font-semibold tw-leading-6 tw-text-blue30',
        { 'tw-bg-orange90 tw-text-orange40': highlight },
        className,
      )}
      {...props}
    />
  ),
)
Label.displayName = 'FeatureText.Label'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  PartialBy<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, type = 'h2', ...props }, ref) => (
  <Heading
    ref={ref}
    type={type}
    className={cn('tw-mb-6 lg:tw-mb-8', className)}
    {...props}
  />
))
Title.displayName = 'FeatureText.Title'

const Description = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('tw-mb-4', className)} {...props} />
))
Description.displayName = 'FeatureText.Description'

const Content = forwardRef<
  ElementRef<typeof Prose>,
  ComponentPropsWithoutRef<typeof Prose>
>(({ className, ...props }, ref) => (
  <Prose
    ref={ref}
    className={cn(
      'tw-mb-4',
      'prose-ul:tw-mb-8 prose-li:tw-mt-4 prose-li:tw-py-0 prose-li:tw-pl-10 prose-li:before:tw-top-0 prose-li:before:tw-h-[24px] prose-li:before:tw-w-[24px] prose-li:before:!tw-bg-transparent prose-li:before:tw-bg-blue-tick',
      className,
    )}
    {...props}
  />
))
Content.displayName = 'FeatureText.Content'

const separatorVariants = cva(
  'tw-m-auto tw-h-px tw-w-full tw-max-w-[163px] md:tw-max-w-[224px] lg:tw-max-w-[308px] xl:tw-max-w-[448px]',
  {
    variants: {
      border: {
        default: 'tw-bg-blue30',
        transparent: 'tw-bg-none',
      },
    },
    defaultVariants: {
      border: 'default',
    },
  },
)

interface SeparatorProps
  extends SeparatorRadix.SeparatorProps,
    VariantProps<typeof separatorVariants> {
  classNameContainer?: string
}

const Separator = forwardRef<
  ElementRef<typeof SeparatorRadix.Root>,
  SeparatorProps
>(
  (
    {
      className,
      classNameContainer,
      border = 'default',
      decorative = true,
      ...props
    },
    ref,
  ) => (
    <div
      className={cn(
        'tw-w-full first:tw-mt-16 last:tw-mt-16 first:md:tw-mt-14 last:md:tw-mt-14 first:lg:tw-mt-20 last:lg:tw-mt-20',
        classNameContainer,
      )}
    >
      <SeparatorRadix.Root
        decorative={decorative}
        ref={ref}
        className={cn(
          separatorVariants({
            border,
            className,
          }),
        )}
        {...props}
      />
    </div>
  ),
)
Separator.displayName = 'FeatureText.Separator'

const Disclaimer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('tw-mb-4 tw-text-sm tw-text-grey30', className)}
      {...props}
    />
  ),
)
Disclaimer.displayName = 'FeatureText.Disclaimer'

const Cta = forwardRef<
  ElementRef<typeof Button>,
  ComponentPropsWithoutRef<typeof Button>
>(({ className, ...props }, ref) => (
  <Button
    ref={ref}
    variant="secondary"
    className={cn('tw-mt-2', className)}
    {...props}
  />
))
Cta.displayName = 'FeatureText.Cta'

export { Root, Label, Title, Description, Content, Disclaimer, Separator, Cta }
